.newRequest {
  color: #fff;
  font-family: segoeuiB;
  display: flex;
  justify-content: center;
  font-size: 16px;
  align-items: center;
  cursor: pointer;
  padding: 0px 15px;
  box-shadow: 5px 9px 8px 0px rgba(191, 191, 191, 0.7);
  background-color: #ffb818;
  height: 36px;
  border-radius: 8px;
  margin-right: 15px;
}

.newRequest:hover {
  background-color: #ffbb4f;
}
