@keyframes swing {
  0% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(10deg) scale(1.3);
  }
  50% {
    transform: rotate(-10deg) scale(1.3);
  }
  75% {
    transform: rotate(10deg) scale(1.3);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

.swing {
  animation: swing 0.5s ease;
  animation-iteration-count: 3;
}
