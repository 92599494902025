.react-calendar {
    width: 100% !important;
  }
  
  .react-calendar__tile--active .appointment {
    color: black !important;
  }

  .react-calendar__tile--now {
    background: green;
  }

  .today {
    background-color: green !important;
  }

  .past-appointment {
    background-color: red !important;
    color: black;
  }
  
  .special-day {
    background-color: tomato !important;
    color: black; /* Yazı rengini siyah yap */
  }
  
  .future-appointment {
    background-color: yellow;
    font-weight: bold;
  }


  .custom-date-picker {
    height: 100%;
  }

  .time-picker  .react-datepicker__time-list {
    height: 275px !important; 
  }

  .react-calendar__tile--active {
    color: black !important;
    background-color: inherit !important;
  }

  .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background-color: #f4f4f4 !important;
  }

  .react-calendar__tile--active.today {
    background-color: green !important;
  }
  
  .react-calendar__tile--active.past-appointment {
    background-color: red !important;
  }

  .react-calendar__tile--active.special-day {
    background-color: tomato !important;
  }

  .react-calendar__tile--active.future-appointment {
    background-color: yellow !important;
  }
  