.rhap_additional-controls {
  display: none !important;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 0px;
}

.rhap_time {
  color: #fff;
}

.rhap_progress-container {
  margin-left: 15px;
  margin-right: 15px;
}

audio::-webkit-media-controls-panel {
  background-color: #474545;
}

audio::-webkit-media-controls-mute-button {
  background-color: #b1d4e0;
  border-radius: 50%;
}

audio::-webkit-media-controls-play-button {
  background-color: transparent;
  border-radius: 50%;
}

audio::-webkit-media-controls-play-button:hover {
  background-color: red;
}

audio::-webkit-media-controls-current-time-display {
  color: #fff;
}

audio::-webkit-media-controls-time-remaining-display {
  color: #fff;
}

/* audio::-webkit-media-controls-timeline {
  background-color: #b1d4e0;
  border-radius: 25px;
  margin-left: 10px;
  margin-right: 10px;
} */

audio::-webkit-media-controls-volume-slider {
  background-color: #b1d4e0;
  border-radius: 25px;
  padding-left: 8px;
  padding-right: 8px;
}

.fade-enter {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-exit-active {
  opacity: 0;
}
