body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "segoeuiRg";
  src: local("segoeuiRg"), url(/src/utils/fonts/segoeui.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "segoeuiB";
  src: local("segoeuiB"), url(/src/utils/fonts/segoeuib.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "segoeuiI";
  src: local("segoeuiI"), url(/src/utils/fonts/segoeuii.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "segoeuiL";
  src: local("segoeuiL"), url(/src/utils/fonts/segoeuil.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "seguibl";
  src: local("seguibl"), url(/src/utils/fonts/seguibl.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "seguibli";
  src: local("seguibli"), url(/src/utils/fonts/seguibli.ttf) format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "MnBd";
  src: local("MnBd"), url(/src/utils/fonts/montserratb.woff) format("truetype");
}
@font-face {
  font-family: "MonBd";
  src: local("MonBd"), url(/src/utils/fonts/monterob.ttf) format("truetype");
}
@font-face {
  font-family: "MonExB";
  src: local("MonExB"), url(/src/utils/fonts/monteroexb.ttf) format("truetype");
}
@font-face {
  font-family: "MonMd";
  src: local("MonMd"), url(/src/utils/fonts/monteromed.ttf) format("truetype");
}
@font-face {
  font-family: "MonNr";
  src: local("MonNr"), url(/src/utils/fonts/monteronr.ttf) format("truetype");
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

li::marker {
  color: #f53f62;
}

.SnackbarContainer-bottom {
  flex-wrap: wrap;
}
