.MuiTable-root {
  border: 2px solid #f1f2f2;
  border-radius: 66px;
  padding: 12px;
  margin-bottom: 18px;
}
.MuiTable-root td {
  background-color: white;
  border: solid #f4f4f4;
  border-width: 2px 2px 2px 2px;
  color: black;
  font-family: segoeuiRg;
}
.MuiTable-root th {
  border: 2px solid white;
  height: 32px;
}
.MuiTable-root .MuiTableRow-root.MuiTableRow-head {
  background-color: #293c51;
  padding: 0px;
}
.MuiTable-root .MuiTableRow-root .MuiTableCell-head {
  color: white;
  font-family: segoeuiRg;
  font-size: 14px;
  font-weight: 500;
  height: 15px;
  width: 100px;
}

.MuiTable-root .MuiTableCell-root {
  padding: 4px;
  height: 50px;
  padding-left: 8px;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiTablePagination-toolbar {
  background-color: white;
}
